import { lazy } from "react";
// import GameCards from "../components/GameCards";
const GameCards = lazy(() => import("../components/GameCards"));
import { useEffect, useRef, useState } from "react";
import { checkOnline, getHomeScreen } from "../services/backend-api";
import useGameStore from "../store/useGameStore";
import useSessionStore from "../store/useSessionStore";
import useAdStore from "../store/useAdStore";
import useBannerAdStore from "../store/useBannerAdStore";
import { setupAndroidListeners } from "../utils/androidListeners";
import {
  EventSource,
  EventTypes,
  HOME_PAGE_PARAMS,
  MODALS,
  PopupType,
  WebToApp,
} from "../constants/Constants";
import { AdPlacement } from "../constants/AdConstants";
import usePageStore from "../store/usePageStore";
import { Pages } from "../constants/PageConstants";
import useAdUnitStore from "../store/useAdUnitStore";
// import Backdrop from "../components/Backdrop";
const Backdrop = lazy(() => import("../components/Backdrop"));
import { Game } from "../types/Game";
import { callWebToAppFunction } from "../utils/androidCallers";
import { EventInfo } from "../types/window";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Sentry from "@sentry/browser";

import { Action, BrowserHistory, Update, createBrowserHistory } from "history";
import OfflineModal from "../components/Modal/ModalStyles/OfflineModal";
import ErrorModal from "../components/Modal/ModalStyles/ErrorModal";
import OfferModal from "../components/Modal/ModalStyles/OfferModal";
import img_game1 from "../assets/img_game3.png";
import HeroBanner from "../components/HeroBanner";
import useEnvStore from "../store/useEnvStore";
import LandingPage from "../components/Modal/ModalStyles/LandingPage";
import useDeepLinkBannerStore from "../store/useDeepLinkBannerStore";
import useContentLinkBannerStore from "../store/useContentLinkStore";

const history: BrowserHistory = createBrowserHistory();

export const Home = () => {
  const [homePageParams] = useSearchParams();
  const navigate = useNavigate();
  const gameStore = useGameStore();
  const setHomePageParams = useSessionStore((state) => state.setHomePageParams);
  const setCurrencyData = useSessionStore((state) => state.setCurrencyData);
  const setCurrentGame = useSessionStore((state) => state.setCurrentGame);
  const setWebViewSessionTimeIn = useSessionStore(
    (state) => state.setWebViewSessionTimeIn,
  );
  const [gamesByCategory, setGamesByCategory] = useState<Map<string, Game[]>>();
  const {
    loadPubInsAd,
    loadInHouseInsAd,
    loadPubRdAd,
    loadInHouseRdAd,
    adLoadFailedDueToOffline,
    adLoadRetryFunc,
  } = useAdStore();
  const { setPage, setDidUserVisitViaLandingPage, setShouldNavBarBeHidden } =
    usePageStore();
  const { setAdUnits, setInHouseAdUnits } = useAdUnitStore();
  const { setDeepLinkBanners } = useDeepLinkBannerStore();
  const { setContentLinkBanners } = useContentLinkBannerStore();
  const { bnAdLoadFailedDueToOffline, loadBNAd, bnAdLoadRetryFunc } =
    useBannerAdStore();
  const [isLoading, setIsLoading] = useState(true);
  const homePageLoadStartTime = useRef(Infinity);

  const { env } = useEnvStore();

  const [currentModal, setCurrentModal] = useState<string | null>(null);
  const [showLoader, setShowLoader] = useState(false);
  const retryHandler = useRef<() => Promise<boolean>>();
  const [showLandingPage, setShowLandingPage] = useState(false);
  const contentId = homePageParams.get("contentId");
  const [deeplinkGameId, setDeeplinkGameId] = useState<string | undefined>();

  const defaultParam = (key: string, value: string) =>
    env.toLowerCase() === "production"
      ? homePageParams.get(key)
      : (homePageParams.get(key) ?? value);

  useEffect(() => {
    if (currentModal) {
      window.document.body.style.overflow = "hidden";
    } else {
      window.document.body.style.overflow = "visible";
    }
  }, [currentModal]);

  useEffect(() => {
    const effectFunc = async () => {
      if (bnAdLoadFailedDueToOffline || adLoadFailedDueToOffline) {
        const modal_viewed_event: EventInfo = {
          eventType: EventTypes.modal_viewed,
          eventProperties: {
            web_timestamp: Date.now(),
            internetstate: useEnvStore.getState().isOnline
              ? "online"
              : "offline",
            popup_type: PopupType.OFFLINE,
            source: EventSource.AD_LOAD,
          },
        };
        callWebToAppFunction(
          WebToApp.ANALYTICS_LISTENER,
          "",
          "",
          "",
          modal_viewed_event,
          null,
          undefined,
        );
        retryHandler.current = async () => {
          useEnvStore.getState().setIsOnline(await checkOnline());
          if (useEnvStore.getState().isOnline) {
            console.log(
              "I am here with onRetryClickedHandler USER_OFFLINE_REPLAY",
            );
            if (adLoadFailedDueToOffline) adLoadRetryFunc();
            if (bnAdLoadFailedDueToOffline) bnAdLoadRetryFunc();
            return true;
          }
          return false;
        };
        setCurrentModal(MODALS.OFFLINE);
      }
    };
    effectFunc();
  }, [bnAdLoadFailedDueToOffline, adLoadFailedDueToOffline]);

  useEffect(() => {
    const effectFunc = async () => {
      setCurrentGame();
      console.info("*******game fetched**********", gameStore);
      // setTemporaryPlacementFlag(AdPlacement.game_start_fsi);
      homePageLoadStartTime.current = Infinity;
      console.log(navigator.userAgent);
      if (env !== "development" && !navigator.userAgent.includes("Version/")) {
        navigate("/oops");
      }
      if (!gameStore.isGamesFetched) {
        // console.time("startloadinghomescreen");
        await getCards();
        homePageLoadStartTime.current = performance.now();
        setWebViewSessionTimeIn(homePageLoadStartTime.current);
        // console.log("i am here start", performance.now());
      } else {
        /**calling ins ad load only once for the fist time and not the subsequent times */
        // loadInsAd(AdPlacement?.game_start_fsi);
        // loadInsAd(AdPlacement?.game_exit_fsi);
        // loadInsAd(AdPlacement?.game_replay_fsi);
        // loadInsAd(AdPlacement?.game_end_fsi);
        // loadInsAd(AdPlacement?.game_pause_fsi);
        setShouldNavBarBeHidden(false);
        setIsLoading(false);
      }
    };
    effectFunc();
  }, []);
  const getCards = async () => {
    console.info("making api call");
    const userIdFromParam = defaultParam(
      HOME_PAGE_PARAMS.userId,
      "c7805784-7dfe-4213-a1b2-69702099bdf6",
    );
    const bundleIdFromParam = defaultParam(
      HOME_PAGE_PARAMS.bundleId,
      "com.arcplay.sample",
    );
    const thirdPartyIdFromParam = defaultParam(
      HOME_PAGE_PARAMS.thirdPartyId,
      "12345654321",
    );
    const accountIdFromParam = defaultParam(
      HOME_PAGE_PARAMS.accountId,
      "3b9d56c9-1e9c-44f3-8240-6d1cd2a9c53e",
    );
    const webviewSessionIdFromParam = defaultParam(
      HOME_PAGE_PARAMS.webViewSessionId,
      "",
    );
    useEnvStore.getState().setIsOnline(await checkOnline());
    if (!useEnvStore.getState().isOnline) {
      const modal_viewed_event: EventInfo = {
        eventType: EventTypes.modal_viewed,
        eventProperties: {
          web_timestamp: Date.now(),
          internetstate: useEnvStore.getState().isOnline ? "online" : "offline",
          popup_type: PopupType.OFFLINE,
          source: EventSource.HOME_LOAD,
        },
      };
      callWebToAppFunction(
        WebToApp.ANALYTICS_LISTENER,
        "",
        "",
        "",
        modal_viewed_event,
        null,
        undefined,
      );
      retryHandler.current = async () => {
        useEnvStore.getState().setIsOnline(await checkOnline());
        if (useEnvStore.getState().isOnline) {
          console.log(
            "I am here with onRetryClickedHandler USER_OFFLINE_REPLAY",
          );
          getCards();
          return true;
        }
        return false;
      };
      setIsLoading(false);
      setCurrentModal(MODALS.OFFLINE);
      return false;
    } else {
      //setUserId(userIdFromParam);
      console.log("userIdFromParam:", userIdFromParam);
      console.log("bundleIdFromParam:", bundleIdFromParam);
      console.log("thirdPartyIdFromParam:", thirdPartyIdFromParam);
      console.log("accountIdFromParam:", accountIdFromParam);
      console.log("webviewSessionIdFromParam:", webviewSessionIdFromParam);
      if (!contentId) setShouldNavBarBeHidden(false);
      setHomePageParams({
        accountId: accountIdFromParam!,
        bundleId: bundleIdFromParam!,
        thirdPartyId: thirdPartyIdFromParam!,
        userId: userIdFromParam!,
        webviewSessionId: webviewSessionIdFromParam!,
        sdkVersion: homePageParams.get("sdkVersion"),
        gaid: homePageParams.get("gaid"),
        contentId,
      });
      setIsLoading(true);
      const success = await getHomeScreen({
        bundleId: bundleIdFromParam!,
        thirdPartyId: thirdPartyIdFromParam!,
        lastLogin: new Date().toISOString(),
        accountId: accountIdFromParam!,
        webviewSessionId: webviewSessionIdFromParam!,
        userId: userIdFromParam!,
        sdkVersion: homePageParams.get("sdkVersion"),
      })
        .then(async (res) => {
          // console.log("i am here end", performance.now());
          // console.timeEnd("startloadinghomescreen");
          // setIsLoading(false);
          //console.log("res", res.data);
          // we are getting user id now from the initial call params
          //const userId = res.data.userId;
          // setUserId(userId);
          const currencyData = res.data.currencyData;
          setCurrencyData(currencyData);
          const categoryRanking = res?.data?.categoryRankings;
          const gamesList = res?.data?.gamesMap;
          // console.log("categoryRanking", categoryRanking);
          console.log("gamesList", gamesList);
          const gameListByRanking = new Map();
          categoryRanking.forEach((category) => {
            gameListByRanking.set(category, gamesList[category]);
          });
          // console.log("gameListByRanking", gameListByRanking);
          gameStore.setCategoryRanking(categoryRanking);
          gameStore.setGames(gamesList);
          const adUnits = res.data.adUnits;
          setAdUnits(adUnits);
          const inHouseAdUnits = res.data.inHouseAdUnits;
          setInHouseAdUnits(inHouseAdUnits ?? null);
          setDeepLinkBanners(res.data.deepLinkBanners);
          setContentLinkBanners(res.data.contentLinkBanners);
          const homePageLoadedEvent: EventInfo = {
            eventType: EventTypes.first_load_success,
            eventProperties: {
              web_timestamp: Date.now(),
              internetstate: useEnvStore.getState().isOnline
                ? "online"
                : "offline",
              url: window.location.href,
              page_type: "Homepage",
              first_loadtime: performance.now(),
            },
          };
          callWebToAppFunction(
            WebToApp.ANALYTICS_LISTENER,
            "",
            "",
            "",
            homePageLoadedEvent,
            null,
            undefined,
          );
          // TODO: Load all ads after SDK fix in the new version
          // loading banner ads
          console.log("sdkVersion", homePageParams.get("sdkVersion"));
          const mediation = adUnits?.find(
            (element) => element.placementName === "game_start_fsi",
          );
          console.log("mediation from the backend call::", mediation?.adClient);
          if (homePageParams.get("sdkVersion")) {
            if (mediation?.adClient === "ironsource") {
              /* for iron source the ad type matters but at the time of load the placement doesn't matter 
              parallel loads are allowed for different ad types 
              load for game start fsi
              load for game play fsr
              */
              console.log(
                `HomePage:game_start_fsi for adclient ${mediation?.adClient}`,
              );
              loadPubInsAd(AdPlacement?.game_start_fsi);
              console.log(
                `HomePage:game_play_fsr for adclient ${mediation?.adClient}`,
              );
              loadPubRdAd(AdPlacement?.game_play_fsr);
            } else {
              loadPubInsAd(AdPlacement?.game_start_fsi);
              console.log("HomePage:game_start_fsi");
              loadPubInsAd(AdPlacement?.game_exit_fsi);
              console.log("HomePage:game_exit_fsi");
              // loadPubRdAd(AdPlacement?.game_play_fsr);
              // console.log("HomePage:game_play_fsr");
              // loadPubInsAd(AdPlacement?.game_replay_fsi);
              // console.log("HomePage:game_replay_fsi");
              // loadPubInsAd(AdPlacement?.game_end_fsi);
              // console.log("HomePage:game_end_fsi");
              // loadPubInsAd(AdPlacement?.game_pause_fsi);
              // console.log("HomePage:game_pause_fsi");
              if (inHouseAdUnits) {
                loadInHouseInsAd(AdPlacement?.game_start_fsi);
                console.log("HomePage:game_start_fsi");
                loadInHouseInsAd(AdPlacement?.game_exit_fsi);
                console.log("HomePage:game_exit_fsi");
                // loadInHouseRdAd(AdPlacement?.game_play_fsr);
                // console.log("HomePage:game_play_fsr");
                // loadInHouseInsAd(AdPlacement?.game_replay_fsi);
                // console.log("HomePage:game_replay_fsi");
                // loadInHouseInsAd(AdPlacement?.game_end_fsi);
                // console.log("HomePage:game_end_fsi");
                // loadInHouseInsAd(AdPlacement?.game_pause_fsi);
                // console.log("HomePage:game_pause_fsi");
              }
            }
          } else {
            loadPubInsAd(AdPlacement?.game_exit_fsi);
            console.log("HomePage:game_exit_fsi");
            loadPubRdAd(AdPlacement?.game_play_fsr);
            console.log("HomePage:game_play_fsr");
          }
          loadBNAd();
          /**
           * The logic for adding the landing page for deeplink
           */
          if (
            contentId &&
            Object.values(gamesList)
              .flat()
              .some((game) => game.id === contentId?.replace("g:", ""))
          ) {
            console.log("HomePage: showing landing page here");
            setShowLandingPage(true);
            setDeeplinkGameId(contentId?.replace("g:", ""));
            setDidUserVisitViaLandingPage(true);
          } else if (
            res.data.newUserFlow &&
            Object.values(gamesList)
              .flat()
              .some((game) => game.id === res.data.newUserFlow?.gameId)
          ) {
            console.log("HomePage: showing landing page here");
            setShowLandingPage(true);
            setDeeplinkGameId(res.data.newUserFlow?.gameId);
            setDidUserVisitViaLandingPage(true);
          } else {
            setShouldNavBarBeHidden(false);
            //setShouldNavBarBeHidden(false)
          }
          setIsLoading(false);
          return true;
        })
        .catch(async (err) => {
          // console.log("error");
          const modal_viewed_event: EventInfo = {
            eventType: EventTypes.modal_viewed,
            eventProperties: {
              web_timestamp: Date.now(),
              internetstate: useEnvStore.getState().isOnline
                ? "online"
                : "offline",
              popup_type: PopupType.UNEXPECTED_ERROR,
              source: EventSource.HOME_LOAD,
            },
          };
          callWebToAppFunction(
            WebToApp.ANALYTICS_LISTENER,
            "",
            "",
            "",
            modal_viewed_event,
            null,
            undefined,
          );
          retryHandler.current = async () => {
            useEnvStore.getState().setIsOnline(await checkOnline());
            if (useEnvStore.getState().isOnline) {
              console.log(
                "I am here with onRetryClickedHandler USER_OFFLINE_REPLAY",
              );
              return await getCards();
            }
            return false;
          };
          if (err?.response?.status === 408) setCurrentModal(MODALS.OFFLINE);
          else setCurrentModal(MODALS.UNEXPECTED_ERROR);

          setIsLoading(false);
          console.log(err);
          Sentry.captureException(err);
          return false;
        });
      return success;
    }
  };
  // Function to handle the event
  function handleClicked(event: MouseEvent | TouchEvent) {
    // console.log("Event type:", event.type);
    // console.log("Coordinates:", event.clientX, event.clientY);
    console.log("Document clicked");
    usePageStore.getState().setDocumentClicked(true);

    document.removeEventListener("click", handleClicked);
    document.removeEventListener("touchstart", handleClicked);
  }
  useEffect(() => {
    setPage(Pages.HOME);
    // Add event listeners for click and touch events
    document.addEventListener("click", handleClicked);
    document.addEventListener("touchstart", handleClicked);
    //return window.removeEventListener("load", onPageFullyLoaded);
  }, []);

  useEffect(() => {
    history.push("/");
    const unlisten = history.listen(backButtonEvent);

    //logic for showing popup warning on page refresh
    window.onbeforeunload = function () {
      return "Data will be lost if you leave the page, are you sure?";
    };
    return () => {
      unlisten();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const backButtonEvent = async ({ action }: Update) => {
    // console.log("home back");
    if (action === Action.Pop) {
      // console.log("I am here onBackButtonEvent");
      // //send analytics to andorid before closeing
      // console.log(
      //   "webview_close analytics event webview session time:",
      //   useSessionStore.getState().webViewSessionTimeIn - performance.now()
      // );
      const homePageLoadedEvent: EventInfo = {
        eventType: EventTypes.webview_close,
        eventProperties: {
          web_timestamp: Date.now(),
          internetstate: useEnvStore.getState().isOnline ? "online" : "offline",
          webview_session_time:
            performance.now() - useSessionStore.getState().webViewSessionTimeIn,
        },
      };
      callWebToAppFunction(
        WebToApp.ANALYTICS_LISTENER,
        "",
        "",
        "",
        homePageLoadedEvent,
        null,
        undefined,
      );
      window.Android.closeWebview();
    }
  };

  useEffect(() => {
    // Set up Android listeners
    const cleanup = setupAndroidListeners();
    console.log("adding event listener");
    return cleanup; // Cleanup listeners when the component unmounts
  }, []);
  useEffect(() => {
    const categoryRanking = gameStore.categoryRanking;
    const allGames = gameStore.games;
    const gamesByCategory = new Map();
    categoryRanking.forEach((category) => {
      gamesByCategory.set(category, allGames[category]);
    });
    setGamesByCategory(gamesByCategory);
  }, [gameStore.categoryRanking, gameStore.games]);

  if (isLoading) {
    return (
      <>
        {currentModal == "OFFLINE" && (
          <OfflineModal
            open={currentModal == "OFFLINE"}
            onRetryClickedHandler={async () => {
              setShowLoader(true);
              if (retryHandler.current && (await retryHandler.current())) {
                setCurrentModal(MODALS.NONE);
              }
              setShowLoader(false);
            }}
            exitShow
          ></OfflineModal>
        )}
        {currentModal == "UNEXPECTED_ERROR" && (
          <ErrorModal
            open={currentModal == "UNEXPECTED_ERROR"}
            onRetryClickedHandler={async () => {
              setShowLoader(true);
              if (retryHandler.current && (await retryHandler.current())) {
                setCurrentModal(MODALS.NONE);
              }
              setShowLoader(false);
            }}
            exitShow
          ></ErrorModal>
        )}
        <Backdrop show={true}>
          <div className="spinner"></div>
        </Backdrop>
      </>
    );
  }
  return (
    <>
      <main className="flex-grow mt-12">
        <div className="mx-auto w-full max-w-screen-xl">
          {showLandingPage && deeplinkGameId && (
            <LandingPage
              gameId={deeplinkGameId.replace("g:", "")}
              onClose={() => {
                setShouldNavBarBeHidden(false);
                setShowLandingPage(false);
              }}
            ></LandingPage>
          )}
          <HeroBanner />
          {gamesByCategory &&
            Array.from(gamesByCategory.entries()).map(
              ([category, games], idx) => {
                return (
                  <GameCards
                    key={idx}
                    category={category}
                    games={games ?? []}
                    setIsLoading={(flag: boolean) => {
                      setIsLoading(flag);
                    }}
                  />
                );
              },
            )}
        </div>
      </main>
      <footer />
      <div
        className="flex justify-center items-center"
        // style={{ height: `${adHeight}px` }}
        style={{ height: `${(50 / 320) * 100}vw` }}
      ></div>
      {currentModal == "OFFLINE" && (
        <OfflineModal
          open={currentModal == "OFFLINE"}
          onRetryClickedHandler={async () => {
            setShowLoader(true);
            if (retryHandler.current && (await retryHandler.current())) {
              setCurrentModal(MODALS.NONE);
            }
            setShowLoader(false);
          }}
          exitShow
        ></OfflineModal>
      )}
      {currentModal == "UNEXPECTED_ERROR" && (
        <ErrorModal
          open={currentModal == "UNEXPECTED_ERROR"}
          onRetryClickedHandler={async () => {
            setShowLoader(true);
            if (retryHandler.current && (await retryHandler.current())) {
              setCurrentModal(MODALS.NONE);
            }
            setShowLoader(false);
          }}
          exitShow
        ></ErrorModal>
      )}
      {currentModal == "OFFER" && (
        <OfferModal
          open={currentModal == "OFFER"}
          // imageUrl={img_game1}
          onCloseClickedHandler={() => setCurrentModal(MODALS.NONE)}
        />
      )}
      {showLoader && (
        <Backdrop show={true}>
          <div className="spinner"></div>
        </Backdrop>
      )}
    </>
  );
};
