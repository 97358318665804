import { useNavigate } from "react-router-dom";
import {
  EventTypes,
  PopupType,
  EventSource,
  WebToApp,
  MODALS,
} from "../../../constants/Constants";
import { checkOnline, gameSessionStart } from "../../../services/backend-api";
import useEnvStore from "../../../store/useEnvStore";
import useGameStore from "../../../store/useGameStore";
import useSessionStore, { FirstGameSession } from "../../../store/useSessionStore";
import { Game } from "../../../types/Game";
import { EventInfo } from "../../../types/window";
import { callWebToAppFunction } from "../../../utils/androidCallers";
import Modal from "../Modal";
import { useRef, useState } from "react";
import Backdrop from "../../Backdrop";
import * as Sentry from "@sentry/browser";
import img_1 from "../../../assets/image_1.jpg";
import OfflineModal from "./OfflineModal";
import ErrorModal from "./ErrorModal";

type OfferModalProps = {
  open: boolean;
  onCloseClickedHandler: () => void;
};

const OfferModal = ({
  open,
  onCloseClickedHandler: onCloseClickedHandler,
}: OfferModalProps) => {
  const retryHandler = useRef<() => Promise<boolean>>();
  const navigate = useNavigate();
  const setGameSessionId = useSessionStore((state) => state.setGameSessionId);
  const homePageParameters = useSessionStore((state) => state.homePageParams);
  const setIsUsersFirstGameSession = useSessionStore(
    (state) => state.setIsUsersFirstGameSession,
  );
  const [currentModal, setCurrentModal] = useState<string | null>(null);
  const [showLoader, setShowLoader] = useState(false);

  const offer = useGameStore((state) => state.offer);
  // const offer = {
  //   offerId:"abc123",
  //   imageUrl:img_1,
  //   gameId:"abc123",
  //   environmentLevelConfigId:"abc"
  // }
  const games = useGameStore<{ [key: string]: Game[] }>((state) => state.games);
  console.log("games::", games);
  const game = Object.values(games)
    .flat()
    .find((game: Game) => game?.id === offer?.gameId);

  const offerBannerClickedHandler = async () => {
    if (game) {
      await gameOpen();
    }
  };
  const gameOpen = async () => {
    useEnvStore.getState().setIsOnline(await checkOnline());
    if (!useEnvStore.getState().isOnline) {
      const modal_viewed_event: EventInfo = {
        eventType: EventTypes.modal_viewed,
        eventProperties: {
          web_timestamp: Date.now(),
          internetstate: useEnvStore.getState().isOnline ? "online" : "offline",
          popup_type: PopupType.OFFLINE,
          source: EventSource.HOME_LOAD,
          game_name: game?.name,
          game: game?.id,
        },
      };
      callWebToAppFunction(
        WebToApp.ANALYTICS_LISTENER,
        "",
        "",
        "",
        modal_viewed_event,
        null,
        undefined,
      );
      retryHandler.current = async () => {
        useEnvStore.getState().setIsOnline(await checkOnline());
        if (useEnvStore.getState().isOnline) {
          console.log(
            "DeepLink: I am here with onRetryClickedHandler USER_OFFLINE_REPLAY",
          );
          //await gameOpen(game);
          await gameOpen();
          return true;
        }
        return false;
      };
      setShowLoader(false);
      setCurrentModal(MODALS.OFFLINE);
      return false;
    } else {
      console.log("user is online");
      setShowLoader(true);
      const success = gameSessionStart({
        userId: homePageParameters.userId,
        gameId: game!.id,
        bundleId: homePageParameters.bundleId,
        startTime: new Date().toISOString(),
        accountId: homePageParameters.accountId,
        gameConfigId: game!.gameConfigId,
        webviewSessionId: homePageParameters.webviewSessionId,
        offerId: offer?.offerId,
      })
        .then(
          (res: {
            data: { gameSessionId: string; isUsersFirstGameSession: boolean };
          }) => {
            // console.log(
            //   "session id created time it took",
            //   performance.now() - time,
            //   "satrt tiume was:",
            //   time
            // );
            //gameSessionId = res?.data?.gameSessionId;
            let updateFirstSessionState: FirstGameSession= {isFirstSessionInBackend:res?.data?.isUsersFirstGameSession}
            if (useSessionStore.getState().isUsersFirstGameSession?.isFirstSessionInFrontend == null){
              updateFirstSessionState = {...updateFirstSessionState,isFirstSessionInFrontend:res?.data?.isUsersFirstGameSession}
            }
            setIsUsersFirstGameSession(updateFirstSessionState)
            setGameSessionId(res?.data?.gameSessionId);
            console.log(
              "Game session id created:",
              res?.data?.gameSessionId,
              " navigating to the game",
              game,
            );
            setShowLoader(false);
            navigate(`/game/${game?.id}`);
            return true;
          },
        )
        .catch((err) => {
          Sentry.captureException(err);
          setShowLoader(false);
          console.error("gameSessionStart error api: ", err);
          const modal_viewed_event: EventInfo = {
            eventType: EventTypes.modal_viewed,
            eventProperties: {
              web_timestamp: Date.now(),
              internetstate: useEnvStore.getState().isOnline
                ? "online"
                : "offline",
              popup_type: PopupType.UNEXPECTED_ERROR,
              source: EventSource.HOME_LOAD,
              game_name: game?.name,
              game_id: game?.id,
            },
          };
          callWebToAppFunction(
            WebToApp.ANALYTICS_LISTENER,
            "",
            "",
            "",
            modal_viewed_event,
            null,
            undefined,
          );
          retryHandler.current = async () => {
            useEnvStore.getState().setIsOnline(await checkOnline());
            if (useEnvStore.getState().isOnline) {
              console.log(
                "DeepLink: onRetryClickedHandler USER_OFFLINE_REPLAY",
              );
              //return await gameOpen(game);
              return await gameOpen();
            }
            return false;
          };
          console.log("DeepLink:MODAL in catch");
          if (err.response?.status === 408) setCurrentModal(MODALS.OFFLINE);
          else setCurrentModal(MODALS.UNEXPECTED_ERROR);
          setShowLoader(false);
          return false;
        });
      return success;
    }
  };
  return (
    <>
      {showLoader && (
        <Backdrop show={false}>
          <div className="spinner"></div>
        </Backdrop>
      )}{" "}
      <Modal open={open}>
        <div className="flex items-center min-h-screen">
          {" "}
          <div
            className="rounded-lg p-6 text-center flex flex-col items-center"
            style={{
              position: "relative",
              // backgroundImage: `url(${imageUrl})`,
              // backgroundSize: "contain",
            }}
          >
            <a onClick={offerBannerClickedHandler}>
              <img
                //src={offer?.imageUrl}
                src={img_1}
                style={{ maxWidth: "88vw", maxHeight: "73vh" }}
              />
            </a>
            <div className="px-4 pt-2 pb-4 space-y-2">
              <button
                className="w-full text-white  rounded-full font-bold"
                style={{
                  backgroundColor: "#aaaaaaaa",
                  width: "32px",
                  height: "32px",
                }}
                onClick={onCloseClickedHandler}
              >
                X
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {currentModal == "OFFLINE" && (
        <OfflineModal
          open={currentModal == "OFFLINE"}
          onRetryClickedHandler={async () => {
            setShowLoader(true);
            if (retryHandler.current && (await retryHandler.current())) {
              setCurrentModal(MODALS.NONE);
            }
            setShowLoader(false);
          }}
          exitShow
        ></OfflineModal>
      )}
      {currentModal == "UNEXPECTED_ERROR" && (
        <ErrorModal
          open={currentModal == "UNEXPECTED_ERROR"}
          onRetryClickedHandler={async () => {
            setShowLoader(true);
            if (retryHandler.current && (await retryHandler.current())) {
              setCurrentModal(MODALS.NONE);
            }
            setShowLoader(false);
          }}
          exitShow
        ></ErrorModal>
      )}
    </>
  );
};
export default OfferModal;

